import { graphql, Link } from "gatsby"
import React from "react"
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap"
import { PageLayout, PageTitle, WorkHistory } from "../components"
import { SEO, Utils } from "../utils"

export default ({ data }) => {
  // const history = data.allMarkdownRemark.edges || []
  // const images = data.allFile.edges || []
  // const imageMap = Utils.getImageMap(images, /\/[work].*\/|$/)
  return (
    <PageLayout pageName="mentor">
      <SEO title="Mentor" />
      <Container
        fluid
        className="container-mentor"
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>
          <span className="mentor-text mt-2">
            Guidons votre chemin vers le succès academique et professionnel
          </span>
        </h1>
        <h4 className="slog-mentor">
          faites équipe avec un mentor expérimenté aujourd'hui
        </h4>
        <p className="d-flex">
          <Link
            target='blank'
            to='https://bit.ly/3wnPZpd'
            as={Link}
            className="button button-pulse mr-4 button button-cv"
          >
            Commencer le mentorat
          </Link>
        </p>
      </Container>

      <Container>
        <Row className='mt-5'>
          <Col md="6" className="text-left mb-5">
            <h3 class="title-mentor">Pourquoi avoir un mentor academique ou professionnel dans le domaine informatique ?</h3>
            <p class="text-muted h4">Se faire mentoré présente de nombreux avantages :</p>
          </Col>

          <Col md="6" className="text-left">
            <div className="mentor-experience">
              <div>
                <h3>
                  👨🏾‍🏫 Guide personnalisé :
                </h3>
                <p>
                  Un mentor vous offre un soutien individualisé en fonction de vos besoins, de vos objectifs et de votre parcours professionnel.
                </p>

              </div>

              <h3>
                🛠️ Expérience pratique :
              </h3>
              <p>
                Bénéficiez de l'expérience concrète de votre mentor dans votre domaine d'activité. il a souvent traversé des situations similaires et peuvent vous fournir des conseils précieux.
              </p>
            </div>

            <div className="mentor-experience">
              <h3>
                💼 Réseautage et opportunités :
              </h3>
              <p>
                Le mentor peut vous introduire dans son réseau professionnel, vous connectant ainsi à des opportunités de croissance, d'emploi ou de collaboration.
              </p>

            </div>

            <div className="mentor-experience">
              <h3>
                🚀 Développement de compétences :
              </h3>
              <p>
                Grâce à des conseils ciblés et à des retours d'expérience, vous pouvez développer rapidement et efficacement vos compétences professionnelles.
              </p>
            </div>
            <div className="mentor-experience">
              <h3>
                🫂 Confiance et motivation :
              </h3>
              <p>
                Un mentor peut vous aider à surmonter les obstacles, à rester concentré sur vos objectifs et à maintenir votre motivation à un niveau élevé.
              </p>
            </div>


          </Col>

        </Row>

        <Row className='mt-5'>

          <Col md="6" className="text-left">
            <Image
              thumbnail
              src={`/images/mentor/regis-maison-du-cafe.jpeg`}
              alt="regis atemengue"
            />
          </Col>

          <Col md="6" className="text-left">

            <h3 class="title-mentor">Rencontrez votre mentor </h3>
            <p class="text-muted h5"> ✌🏿 Salut Ici Regis Atemengue !!!</p>

            <p>
              Je suis ingénieur logiciel passionné avec plus de 5 ans d'expérience dans l'industrie.
            </p>

            <p>

              Mon parcours m'a permis de travailler sur une multitude de projets passionnants, allant du développement d'applications web à la conception de logiciels complexes.
              Au fil des années, j'ai acquis une expertise approfondie dans divers langages de programmation, frameworks et technologies émergentes.
            </p>

            <p>

              Ce qui me distingue, c'est ma passion pour la transmission des connaissances et et le mentorat dans le domaine informatique.
              En parallèle à ma carrière d'ingénieur, j'alloue une part de mon temps à accompagner et à guider ceux qui aspirent à se reconvertir professionnellement ou à approfondir leurs compétences dans ce domaine passionnant.

              Je crois fermement que partager ses connaissances est essentiel pour favoriser la croissance et l'innovation dans notre domaine.
            </p>

            <p>

              J'ai eu le privilège d'accompagner des étudiants, venant de différents horizons tels que le Cameroun, l'Allemagne, la France et le Maroc.
              À travers cette expérience enrichissante, j'ai guidé ces étudiants dans leur apprentissage du génie logiciel et du développement d'applications.
            </p>


            <p>

              Que vous soyez un étudiant débutant ou un professionnel chevronné,
              je suis là pour vous aider à atteindre vos objectifs en matière de génie logiciel.
              Mon engagement envers votre réussite est inébranlable,
              et je suis déterminé à vous accompagner à chaque étape de votre parcours
            </p>

          </Col>

        </Row>
      </Container>


      <Container fluid>
        <Row className='pt-10'>
          <Col className='col-md-12'>
            <div className="call-action">
              <div className="container">
                <div className="row align-items-center g-0">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div>
                      <h1 className="text-white fw-bold pe-lg-8">Devenez étudiant dès aujourd'hui &amp;
                        Rejoignez une communauté d'apprentissage dynamique
                      </h1>

                      <Link
                        target='blank'
                        to='https://bit.ly/3wnPZpd'
                        as={Link}
                        className="button button-pulse mr-4 mb-4 button button-cv"
                      >
                        Commencer le mentorat
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {/* <p className="d-flex">
            <Link
              as={Link}
              className="button button-pulse mr-4 button button-cv"
            >
              Commencer le mentorat maintenant
            </Link>
          </p> */}

        </Row>
      </Container>


      <Container>
        <Row className='mt-5'>
          <section id="what-we-do">
            <div class="container-fluid">
              <h2 class="mb-2 title-mentor text-left">Decouvez les offres de mentorat</h2>
              <p class="text-muted h4 text-left">Offres adaptées à vos besoins avec accompagnement Personnalisé vers l'Excellence Technique
              </p>
              <div class="row mt-5">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/001-lobtention-du-diplome.png`}
                        alt="diplome"
                      />
                      <h3 class="card-title">Mentorat Individuel Academique ou Professionnel</h3>
                      <p class="card-text">
                        Sessions personnalisées pour votre evolution academique ou professionnel.
                      </p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/002-presentation.png`}
                        alt="groupe"
                      />
                      <h3 class="card-title">Mentorat en  Groupe</h3>
                      <p class="card-text">
                        Ateliers ou séminaires en groupe sur une technologie ou un sujet specifique.
                        Session interactives, avec des exercices pratiques et des discussions en groupe sur des thèmes des specifiques.
                      </p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/008-livre-ouvert.png`}
                        alt="soutenance"
                      />
                      <h3 class="card-title">Session Review Projet de Soutenance</h3>
                      <p class="card-text">
                        Analyse, Preparation et feedback constructif pour reussir votre projet de soutenance.
                      </p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>


              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/005-diplome.png`}
                        alt="formations"
                      />
                      <h3 class="card-title">Formations sur des Technologies Specifiques</h3>
                      <p class="card-text">
                        Cours ou sessions de formation sur des langages de programmation, des frameworks, des outils de développement, etc.
                      </p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/017-remarques.png`}
                        alt="soutenance"
                      />
                      <h3 class="card-title">Soutien Projets Personnels ou Professionnels</h3>
                      <p class="card-text">Assistance technique et conseils pour les projets de développement d'applications personnels ou professionnels.</p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <div class="card">
                    <div class="card-block">
                      <Image
                        width={64}
                        height={64}
                        src={`/images/mentor/conseil.png`}
                        alt="soutenance"
                      />
                      <h3 class="card-title">Conseils en Developpement de Carriere</h3>
                      <p class="card-text">
                        Accompagnement dans la planification et la progression de votre carrière dans le domaine du génie logiciel
                        et du développement d'applications.
                        .</p>
                      {/* <a href="https://www.fiverr.com/share/qb8D02" title="Read more" class="read-more" >Read more<i class="fa fa-angle-double-right ml-2"></i></a> */}
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </section>
        </Row>
        <Row className='mt-5'>
          <section id="what-we-do">
            <div class="container-fluid">
              <h2 class="mb-2 text-left title-mentor">Processus de Mentorat</h2>
              <p class=" text-left text-muted h5">Découvrez notre processus de mentorat sur mesure.</p>
            </div>

          </section>
        </Row>

      </Container>

      <Container>
        <section class="our-blog p-0 m-0 bg-silver">
          <div class="container work-process  pb-5 pt-5">
            {/* <!-- ============ step 1 =========== --> */}
            <div class="row">
              <div class="col-md-7">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape 1</p>
                        <h2 class="m-0 p-0">01</h2>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <h5 className='font-weight-bold' >Session Decouverte</h5>
                      <p><small>
                        Nous commençons par une consultation initiale gratuite pour discuter de vos objectifs,
                        de vos défis et de vos attentes par rapport au mentorat.
                        Cette étape nous permet de mieux nous comprendre et d'établir une base solide pour notre collaboration.
                      </small></p>
                    </div>
                  </div>
                  {/* <div class="process-line-l"></div> */}
                </div>
              </div>
              {/* <div class="col-md-2"></div> */}
              {/* <div class="col-md-5">
                <div class="process-point-right"></div>
              </div> */}
            </div>
            {/* <!-- ============ step 2 =========== --> */}
            <div class="row mt-3">

              <div class="col-md-3">
                {/* <div class="process-point-left"></div> */}
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-7">
                <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-9">
                      <h5 className="font-weight-bold" >Évaluation des Besoins</h5>
                      <p><small>Ensuite, nous procédons à une évaluation approfondie de vos compétences actuelles en génie logiciel et développement d'applications.
                        Cela nous permet de déterminer vos besoins spécifiques et de personnaliser le mentorat en conséquence. </small></p>
                    </div>
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape 2</p>
                        <h2 class="m-0 p-0">02</h2>
                      </div>
                    </div>

                  </div>
                  {/* <div class="process-line-r"></div> */}
                </div>
              </div>

            </div>
            {/* <!-- ============ step 3 =========== --> */}
            <div class="row mt-3">
              <div class="col-md-7">
                <div class="process-box process-left" >
                  <div class="row">
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape</p>
                        <h2 class="m-0 p-0">03</h2>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <h5 className="font-weight-bold">Planification Personnalisée</h5>
                      <p><small>En fonction de vos objectifs et de notre évaluation, nous élaborons ensemble un plan d'action personnalisé.
                        Ce plan définit les étapes à suivre,
                        les sujets à aborder et les ressources à utiliser pour atteindre vos objectifs de manière efficace.</small></p>
                    </div>
                  </div>
                  {/* <div class="process-line-l"></div> */}
                </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-5">
                {/* <div class="process-point-right"></div> */}
              </div>
            </div>
            {/* <!-- ============ step 4 =========== --> */}
            <div class="row mt-3">
              <div class="col-md-3">
                {/* <div class="process-point-left"></div> */}
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-7">
                <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-9">
                      <h5 className="font-weight-bold" >Séances de Mentorat</h5>
                      <p><small>Les séances de mentorat sont des sessions individuelles où nous travaillons sur des sujets spécifiques en fonction de vos besoins.
                        Ces sessions peuvent inclure des discussions sur des concepts techniques, des revues de code,
                        des exercices pratiques, etc.. </small></p>
                    </div>
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape</p>
                        <h2 class="m-0 p-0">04</h2>
                      </div>
                    </div>

                  </div>
                  <div class="process-line-r"></div>
                </div>
              </div>
            </div>
            {/* <!-- ============ step 3 =========== --> */}
            <div class="row mt-3">
              <div class="col-md-7">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape</p>
                        <h2 class="m-0 p-0">06</h2>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <h5 className="font-weight-bold" >Feedback et Suivi</h5>
                      <p><small>
                        Après chaque séance, je vous fournis un feedback détaillé sur vos progrès, ainsi que des recommandations pour continuer à avancer.
                        Je reste également disponible entre les séances pour répondre à vos questions et vous apporter un soutien continu.</small></p>
                    </div>
                  </div>
                  <div class="process-line-l"></div>
                </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-5">
                {/* <div class="process-point-right process-last"></div> */}
              </div>
            </div>
            {/* <!-- ============ --> */}

            <div class="row mt-3">
              <div class="col-md-3">
                {/* <div class="process-point-left"></div> */}
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-7">
                <div class="process-box process-right" data-aos="fade-left" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-9">
                      <h5 className="font-weight-bold" >Ajustements et Réorientations</h5>
                      <p><small>
                        Au fur et à mesure que nous avançons dans le processus de mentorat,
                        nous sommes flexibles pour ajuster notre plan d'action en fonction de vos besoins changeants et de vos progrès.
                        Nous pouvons réorienter nos efforts pour nous concentrer sur de nouveaux objectifs ou sur des domaines où vous rencontrez des difficultés particulières. </small></p>
                    </div>
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape</p>
                        <h2 class="m-0 p-0">07</h2>
                      </div>
                    </div>
                  </div>
                  <div class="process-line-r"></div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-7">
                <div class="process-box process-left" data-aos="fade-right" data-aos-duration="1000">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="process-step">
                        <p class="m-0 p-0">Etape</p>
                        <h2 class="m-0 p-0">07</h2>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <h5 className="font-weight-bold" > Bilan et Avancement</h5>
                      <p><small>
                        À intervalles réguliers, nous faisons le point sur vos progrès et vos réalisations par rapport à vos objectifs initiaux.
                        Ensemble, nous évaluons ce qui fonctionne bien et ce qui peut être amélioré, afin de maximiser l'efficacité de notre collaboration.
                      </small></p>
                    </div>
                  </div>
                  <div class="process-line-l"></div>
                </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-5">
                {/* <div class="process-point-right process-last"></div> */}
              </div>
            </div>
          </div>
        </section>
      </Container>

      <Container>
        <section id="what-we-do">
          <div className="container-fluid">
            <h2 className="mb-2 title-mentor h1 text-left">Temoigagnes</h2>
            <p className="text-muted h5 text-left ">Explorez les témoignages inspirants de mes étudiants.</p>
          </div>

          <div className="container mt-5">
            <div className="row">
              <div className="col-md-4">
                <div className="testimonial-1 rounded"><img className="mb-3" src="/images/mentor/citation.png" width="40" />
                  <br />
                  <p>

                    Lors de mon stage au MINADER, j’ai été confronté à beaucoup de difficultés de part ma recherche d’un thème de soutenance,
                    ma montée en compétences et l’acquisition de softs skills comportementaux et professionnelles.
                    De l’accompagnement personnel au soutien professionnel Regis ATEMENGUE mon maitre de stage été attentif à mes besoins.

                    Aujourd’hui, je peux le dire avec fierté et la plus grande des énergies, c’est le meilleur des mentors 🔥.
                  </p>
                  <br />

                  <div className="d-flex flex-row align-items-center"><img className="rounded-circle" src="https://media.licdn.com/dms/image/D4E03AQEZuOqUc_Xb9A/profile-displayphoto-shrink_800_800/0/1682063418261?e=1714608000&v=beta&t=-l6zVAaIR0rGSedzKSzEa_z8kW5jsTC6aAfJfb4iV8Q" width="40" />
                    <div className="ml-2 about"><h4><span className="d-block name-testimony ">Asaph AKOA 🇫🇷 </span></h4><span className="place">Data Protection Analyst | Cybersecurity | Developper</span></div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonial-1 rounded"><img className="mb-3" src="/images/mentor/citation.png" width="40" />
                  <p>
                    J'ai eu l'immense privilège d'être mentoré par Mr Regis Atemengue à partir de mon Master1 à l'université de Yaoundé1.
                    Il a généreusement partagé avec moi ses nombreuses années d'expérience et de connaissances
                    .Ses anecdotes et ses conseils pratiques m'ont été d'une grande valeur. Aussi, son soutien et ses conseils ont été
                    inestimables pour mon développement personnel et professionnel.
                    Il contribue ainsi à la personne que je suis et deviens. Je lui en serai toujours reconnaissant.
                    <br /><br /></p>
                  <div className="d-flex flex-row align-items-center"><img className="rounded-circle" src="https://ui-avatars.com/api/?name=Dave+CHEDJOUN" width="40" />
                    <div className="ml-2 about"><h4><span className="d-block name-testimony ">Dave CHEDJOUN 🇨🇲</span></h4><span className="place">Etudiant UY-1 | Etudiant Génie en Logiciel</span></div>
                  </div>
                </div>
              </div>


              <div className="col-md-4">
                <div className="testimonial-1 rounded"><img className="mb-3" src="/images/mentor/citation.png" width="40" />
                  <p>
                    Je suis encore sous le choc de l'efficacité des sessions de mentorat avec Regis. En seulement deux sessions de travail, il a réussi à m'extraire des abysses de l'incompréhension où je me débattais depuis des mois. En quatre heures seulement,
                    j'ai enfin compris ce qui me semblait insaisissable malgré toute ma bonne volonté d'apprendre. C'est tout simplement impressionnant et je suis extrêmement reconnaissant pour son aide précieuse
                    <br /><br />
                    <br />
                  </p>
                  <div className="d-flex flex-row align-items-center"><img className="rounded-circle" src="https://ui-avatars.com/api/?name=Michelle+NSTAMA" width="40" />
                    <div className="ml-2 about"><h4><span className="d-block name-testimony ">Cecile Michelle NSTAMA  🇩🇪 </span></h4><span className="place">Etudiante | Big Data</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

      </Container>
    </PageLayout>
  )
}


